<template>
    <card v-bind="cardProps" :flat="slidesPerView > 1">
        <div class="w-full">
            <slider v-if="displayElements.length || $slots.first_slides || $slots.last_slides" :show-arrows="displayElements.length > slidesPerView" :slides-per-view="slidesPerView" :next-after="10000" loop>
				<div v-if="$slots.first_slides" class="min-h-240">
					<slot name="first_slides"/>
				</div>
                <slider-item v-for="(element, elementKey) in displayElements" :key="`elements-card-${elementKey}-${element.id}`">
					<lazy-sponsor-card v-if="element.model_name === 'sponsor'" :sponsor="element" :flat="slidesPerView === 1" no-spacing :xs-tile="xsTile" class="h-full w-full"/>
					<lazy-event-impression-card v-else-if="element.model_name === 'event_impression'" :to="$web('events.impressions.index', event._key)" :impression="element" :flat="slidesPerView === 1" no-spacing :xs-tile="xsTile" class="h-full w-full"/>
					<lazy-ad-card v-else-if="element.model_name === 'ad'" :ad="element" :flat="slidesPerView === 1" no-spacing :xs-tile="xsTile" class="h-full w-full"/>
                </slider-item>
				<div v-if="$slots.last_slides" class="min-h-240">
					<slot name="last_slides"/>
				</div>
            </slider>
        </div>
    </card>
</template>

<script>
import {CardMixin} from "@spoferan/nuxt-spoferan/mixins";

export default {
    name: 'EventFeatureElementCard',

    mixins: [CardMixin],

    props: {
        // Number of slides per view (slides visible at the same time on slider's container).
        slidesPerView: {
            type: Number,
            default: 1
        },

        elements: {
            type: Array,
            required: true
        },

        ads: {
            type: Array,
            default: null
        },

        event: {
            type: Object,
            required: true
        }
    },

    computed: {
        displayElements() {
            if (!this.ads || !this.ads.length) {
                return this.elements;
            }

            const adPosition = Math.min(2, this.elements.length); // Insert an ad after every two elements
            let currentAdIndex = 0;
            return this.elements.length ? this.elements.reduce((list, elem, i) => {
                list.push(elem);
                if(((i+1) % adPosition) === 0) {
                    list.push(this.ads[currentAdIndex]);
                    currentAdIndex++;
                    if (currentAdIndex > this.ads.length - 1) {
                        currentAdIndex = 0;
                    }
                }

                return list;
            }, []) : this.ads;
        }
    }
}
</script>
